import React, { useEffect, useRef, useState } from "react";
import { Profile } from "../profile/Profile";
import { Header } from "../../../commen/Header";
import "react-image-lightbox/style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  GetDataWithToken,
  GetDataWithTokenForLazy,
  PostDataWithToken,
  PostDataWithTokenNodeServer,
  PostDataWithTokenNodeServerFormData,
} from "../../../ApiHalper/ApiHelper";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb";
import Cookies from "js-cookie";
import { Loader } from "../../../commen/Loader";

export const Images = ({ data }) => {
  const [selectUnSelectImg, setSelectUnSelectImg] = useState("");
  const [img, setImg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [unSelectedId, setUnSelectedId] = useState([]);
  const [functions, setFunctions] = useState({ selectedFunction: undefined, functions: [], });
  const [lodar, setLodar] = useState(true);
  const loaction = useLocation();
  const navigate = useNavigate();
  const selectUnSelectImgRef = useRef(false);
  selectUnSelectImgRef.current = selectUnSelectImg;
  const openLightbox = (index) => { setPhotoIndex(index); setIsOpen(true); };

  useEffect(() => {
    GetDataWithToken(
      `customer/event-functions/${loaction?.state?.event_id}/${loaction?.state?.function_id}`
    ).then((res) => {
      setLodar(true);
      if (res?.success) {
        setImg(res?.data);
        setLodar(false);
      } else if (res.status === 401) {
        navigate("/login");
        Cookies.remove("token");
        toast.error("Session Expire ! Please Login again");
      }
    });

    GetDataWithToken(
      `customer/event/${loaction?.state?.event_id}/functions/list`
    ).then((res) => {
      if (res?.success) {
        setFunctions((prevFunctions) => ({
          ...prevFunctions,
          functions: res.data,
        }));
      } else if (res.status === 401) {
        navigate("/login");
        Cookies.remove("token");
        toast.error("Session Expire ! Please Login again");
      }
    });
  }, [loaction]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window?.scrollY;
      const totalHeight = document?.body?.scrollHeight;
      const windowHeight = window?.innerHeight;


      const linksFilter = document.querySelector('.links-filter');
      if (scrollPosition > 410) {
        console.log(scrollPosition);
        linksFilter?.classList?.add("filter-sticky");
      } else {
        linksFilter?.classList?.remove("filter-sticky");
      }

      if (!isLoading && scrollPosition + windowHeight + 1 >= totalHeight) { // ye + 1 scroll ke liye add kiya hai jeese ki ye api call ho jaaye 
        if (img?.next_page_url !== null && img?.next_page_url) {
          setIsLoading(true);

          GetDataWithTokenForLazy(img?.next_page_url)
            .then((res) => {
              const newNextPageUrl = res?.data?.next_page_url;
              if (res?.data && Array?.isArray(res?.data?.data?.image)) {
                setImg((prevImg) => {
                  const filteredImages = res?.data?.data?.image?.filter(
                    (newImage) => {
                      return !prevImg?.data?.image?.some(
                        (oldImage) => oldImage?.id === newImage?.id
                      );
                    }
                  );
                  return {
                    ...prevImg,
                    next_page_url: newNextPageUrl,
                    data: {
                      ...prevImg?.data,
                      image: [...prevImg?.data?.image, ...filteredImages],
                    },
                    current_page: res?.current_page,
                  };
                });
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [img, isLoading]);

  const handleCheckBox = (e, data) => {
    const selectedImg = [...selectedId];
    const unSelectedImg = [...unSelectedId];

    if (selectUnSelectImg === true || selectUnSelectImg === false) {
      if (!e.target.checked && selectUnSelectImg === true) {
        const index = selectedImg.indexOf(data.id);
        if (index !== -1) selectedImg.splice(index, 1);
        unSelectedImg.push(data.id);
      } else if (selectUnSelectImg === false && e.target.checked) {
        const index = unSelectedImg.indexOf(data.id);
        if (index !== -1) unSelectedImg.splice(index, 1);
        selectedImg.push(data.id);
      }
      setSelectedId(selectedImg);
      setUnSelectedId(unSelectedImg);
    } else {
      if (e.target.checked) {
        if (data?.is_selected === 1) {
          const index = unSelectedImg?.indexOf(data.id);
          if (index !== -1) unSelectedImg?.splice(index, 1);
        } else {
          selectedImg.push(data.id);
        }
      } else {
        if (selectUnSelectImg == false && data?.is_selected === 0) {
          const index = selectedImg.indexOf(data.id);
          if (index !== -1) selectedImg.splice(index, 1);
        } else {
          unSelectedImg.push(data.id);
        }
      }
      setSelectedId(selectedImg);
      setUnSelectedId(unSelectedImg);
    }
  };

  const handleSaveSelectedImage = () => {
    if (selectUnSelectImg === true || selectUnSelectImg === false) {
      Swal.fire({
        title: "Images",
        text: `You have ${selectedId?.length > 0
          ? `Selected ${selectedId?.length}`
          : unSelectedId?.length > 0
            ? `Unselected ${unSelectedId?.length} `
            : selectUnSelectImg === true
              ? `Selected ${img?.total}`
              : selectUnSelectImg === false
                ? `Unselected ${img?.total}`
                : "none"
          }/${img?.total} images ?`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          const type =
            selectUnSelectImg === true
              ? 1
              : selectUnSelectImg === false
                ? 0
                : null;
          PostDataWithToken(
            `customer/event/all/${type}/images/${loaction?.state?.event_id}`,
            type == 0
              ? { selected: selectedId.join(",") }
              : { unselected: unSelectedId.join(",") }
          ).then((res) => {
            setLodar(true);
            if (res?.success) {
              setLodar(false);
              toast?.success(res?.message);
            }
          });
        }
      });
      return;
    }
    PostDataWithToken(
      `customer/event-function/select/images/${loaction?.state?.event_id}/${loaction?.state?.function_id}`,
      { selected: selectedId.join(","), unselected: unSelectedId.join(",") }
    ).then((res) => {
      setLodar(true);
      if (res?.success) {
        setLodar(false);
        toast.success(res?.message);
      }
    });
  };

  const handleSelectAllImages = (type) => {
    setSelectedId([]);
    setUnSelectedId([]);
    setSelectUnSelectImg(type);
    if (type == true) {
      const inputs = document?.querySelectorAll("#flexCheckDefault");
      inputs.forEach((input) => {
        input.checked = true;
      });
    } else if (type == false) {
      const inputs = document?.querySelectorAll("#flexCheckDefault");
      inputs.forEach((input) => {
        input.checked = false;
      });
    }
  };

  const handleGetImagesByFunctions = (function_id, name) => {
    setLodar(true);
    setFunctions((prevFunctions) => ({
      ...prevFunctions,
      selectedFunction: name,
    }));
    GetDataWithToken(
      `customer/event-functions/${loaction?.state?.event_id}/${function_id}`
    ).then((res) => {
      setLodar(false);
      setImg(res?.data);
    });
  };

  const handleGetAllImages = (name) => {
    setLodar(true);
    setFunctions((prevFunctions) => ({
      ...prevFunctions,
      selectedFunction: "ALL IMAGES",
    }));
    GetDataWithToken(
      `customer/event/all/images/${loaction?.state?.event_id}`).then((res) => {
        setLodar(false);
        setImg(res?.data);
      });
  };
  const handleFaceRecognize = (e) => {
    const file = e.target.files[0];
    const formDataToSend = new FormData();
    formDataToSend.append('image', file);
    formDataToSend.append('test', "test");


    console.log(formDataToSend, e.target.files[0])

    PostDataWithTokenNodeServerFormData('https://cce4-2405-201-5c13-f8e0-619e-9b76-d96b-fff0.ngrok-free.app/upload', formDataToSend).then((res) => {
      console.log(res)
    })
  }
  return (
    <>
      <div className="tanent-sec">
        <Header />
        <div className="after-landing-section">
          <Profile activeClass={"events"} />
        </div>
        {lodar ? (
          <Loader />
        ) : (
          <div className="tanent_images">
            <div className="links-filter">

              <BreadCrumb data={{
                state: loaction?.state,
                folderRoute: "/Event/folder",
                eventClass: "linknames",
                functionClass: "linknames",
                selectedFunction: functions?.selectedFunction || loaction?.state?.function_name,
              }} />

              <div className="filters">
                <button
                  className="btn dropdown-toggle filter-btn me-2 me-sm-3"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {functions?.selectedFunction
                    ? functions?.selectedFunction
                    : "Functions"}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {Object?.entries(functions?.functions)?.map(([key, value]) => (
                    <li key={key}>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleGetImagesByFunctions(key, value)}
                      >
                        {value}
                      </button>
                    </li>
                  ))}
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={handleGetAllImages}
                    >
                      All
                    </button>
                  </li>
                </ul>

                <button
                  className="btn filter-btn dropdown-toggle me-2 me-sm-3"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Select
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleSelectAllImages(true)}
                    >
                      Selecte All
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleSelectAllImages(false)}
                    >
                      UnSelecte All
                    </button>
                  </li>
                </ul>
                <button
                  className="btn filter-btn"
                  onClick={handleSaveSelectedImage}
                >
                  Save
                </button>
              </div>

            </div>
            <div className="after-landing-section">
              <div className="event-images row">
                {img?.data?.image &&
                  img?.data?.image?.length > 0 &&
                  img?.data?.image?.map((item, index) => (
                    <div className={"col-xxl-3 col-md-4 col-sm-6"} key={index}>
                      <div className="event-imgs">
                        <a
                          href="javascript:void(0)"
                          className="porto-item"
                          onClick={() => openLightbox(index)}
                        >
                          <LazyLoadImage
                            src={item?.image || item?.image_og} // Use image_og if available, otherwise fallback to image
                            alt="portfolio thumbnail"
                            className="img-fluid object-height"
                            effect="blur"
                            loading="lazy"
                          />
                        </a>
                        <div className="imgselect">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            defaultChecked={
                              item?.is_selected === 1 || selectUnSelectImg
                            }
                            onChange={(e) => handleCheckBox(e, item)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"flexCheckDefault"}
                          ></label>
                        </div>
                      </div>

                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        {!img?.data?.image && <h1 className="text-center title42">This event function have no images</h1>}
        {isOpen && img && img?.data && img?.data?.image && (
          <div>
            <Lightbox
              mainSrc={
                img?.data?.image[photoIndex]?.image_og ||
                img?.data?.image[photoIndex]?.image
              }
              imageTitle={photoIndex + 1 + "/" + img?.data?.image?.length}
              nextSrc={
                img.data.image[(photoIndex + 1) % img?.data?.image?.length]
                  ?.image_og ||
                img.data.image[(photoIndex + 1) % img?.data?.image?.length]
                  ?.image
              }
              prevSrc={
                img.data.image[
                  (photoIndex + img?.data?.image?.length - 1) %
                  img?.data?.image?.length
                ]?.image_og ||
                img.data.image[
                  (photoIndex + img?.data?.image?.length - 1) %
                  img?.data?.image?.length
                ]?.image
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() => {
                setPhotoIndex(
                  (photoIndex + img?.data?.image?.length - 1) %
                  img?.data?.image?.length
                );
              }}
              onMoveNextRequest={() => {
                setPhotoIndex((photoIndex + 1) % img?.data?.image?.length);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
