import React, { useEffect, useRef, useState } from "react";
import { PostData } from "../ApiHalper/ApiHelper";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { BtnLoader } from "../commen/BtnLoader";
export const LoginWithCode = () => {
  const [showOtpToken, setShowOtpToken] = useState({
    showOtp: false,
    token: "",
  });
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [btnLoader, setBtnLoader] = useState(false);

  const navigate = useNavigate();
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  // const input6Ref = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleInputChange = (event, nextInput, prevInput) => {
    if (event.key === "Backspace") {
      prevInput?.current?.focus();
      return;
    }
    if (event.target.value.length >= 1) {
      nextInput?.current?.focus();
    }
  };

  const handleLoginWithLink = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    setBtnLoader(true);

    if (showOtpToken?.showOtp) {
      PostData("event/event-tokens/otp/verify", {
        token: showOtpToken?.token,
        otp: `${input1Ref?.current?.value}${input2Ref?.current?.value}${input3Ref?.current?.value}${input4Ref?.current?.value}`,
      }).then((res) => {
        if (res?.success) {
          Cookies.set("gustToken", res?.data?.token);
          toast.success(res?.message);
          setBtnLoader(false);
          navigate("/guest/Folder");
        } else {
          toast?.error(res?.data?.message);
          setBtnLoader(false);
        }
      });
    } else {
      PostData("event/event-tokens/login", { token: showOtpToken?.token }).then(
        (res) => {
          if (res?.success) {
            console.log(res?.message);
            if (res?.data?.type !== 1) {
              setBtnLoader(false);
              Cookies.set("gustToken", res?.data?.token);
              navigate("/guest/Folder");
            }
            setBtnLoader(false);
            toast.success(res?.message);
            setShowOtpToken((prevState) => ({
              ...prevState,
              showOtp: true,
            }));
          } else {
            setBtnLoader(false);
            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  const resendOTP = () => {
    setMinutes(1);
    setSeconds(0);
    PostData("event/event-tokens/otp/resend", {
      token: showOtpToken?.token,
    }).then((res) => {
      if (res?.success) {
        toast?.success(res?.message);
      } else {
        toast.error(res?.data?.message);
      }
    });
  };

  return (
    <>
      <div className="tab-pane fade" id="inputCode">
        <div className="detail">
          <h1>Welcome back</h1>
          <p>
            Please Enter your <strong>Code</strong> which you get from studio or
            login credentials
          </p>
        </div>
        <form>
          {!showOtpToken?.showOtp && (
            <div className="position-relative iconinputs">
              <label htmlFor="Code" className="form-label">
                Code/Keyword
              </label>
              <input
                type="text"
                className="form-control"
                id="Code"
                placeholder="Enter your code here"
                onChange={(e) =>
                  setShowOtpToken((prevState) => ({
                    ...prevState,
                    token: e?.target?.value,
                  }))
                }
              />

              <span className="inputicon">
                <i className="fa-solid fa-user" />
              </span>
            </div>
          )}
          {showOtpToken?.showOtp && (
            <div className="mb-xxl-5 mb-4">
              <label htmlFor="Code" className="form-label">
                Enter OTP
              </label>
              <div className="otp-input">
                <input
                  type="text"
                  className="form-control"
                  placeholder={"*"}
                  ref={input1Ref}
                  maxLength={1}
                  onKeyUp={(event) => handleInputChange(event, input2Ref)}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder={"*"}
                  ref={input2Ref}
                  maxLength={1}
                  onKeyUp={(event) =>
                    handleInputChange(event, input3Ref, input1Ref)
                  }
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder={"*"}
                  ref={input3Ref}
                  maxLength={1}
                  onKeyUp={(event) =>
                    handleInputChange(event, input4Ref, input2Ref)
                  }
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="*"
                  ref={input4Ref}
                  maxLength={1}
                  onKeyUp={(event) =>
                    handleInputChange(event, input5Ref, input3Ref)
                  }
                />
              </div>
              <div className="resnd-otp text24 mt-3">
                {seconds > 0 || minutes > 0 ? (
                  <p className="time">
                    Time Remaining :{" "}
                    <span className="fw-bold">
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  </p>
                ) : (
                  <div className="resend-btn d-flex">
                    <p>Don’t get code?</p>
                    <a
                      // href="#!"
                      disabled={seconds > 0 || minutes > 0}
                      onClick={resendOTP}
                      className="ms-1 btn p-0 fw-bold border-0 bg-transparent"
                    >
                      Resend OTP
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}

          <button
            className="btns btns--calypso login-btn"
            onClick={(e) => handleLoginWithLink(e)}
          >
            {/* <span>{btnLoader ? <BtnLoader /> : "Submit"}</span> */}
            <span>{btnLoader ? <BtnLoader /> : "Send" }</span>
          </button>
        </form>
      </div>
    </>
  );
};
