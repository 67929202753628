import { configureStore } from "@reduxjs/toolkit";
import { userInfoReducer ,baseUrlReducer,isLoggedInReducer,imageBaseUrlReducer,logoReducer, FaceDataReducer} from "./Action";

const store = configureStore({
  reducer: {
    userInfo: userInfoReducer.reducer, 
    baseUrl:baseUrlReducer.reducer,
    isLoggedInReducer:isLoggedInReducer.reducer,
    imageBaseUrlReducer:imageBaseUrlReducer.reducer,
    logoReducer:logoReducer.reducer,
    FaceDataReducer:FaceDataReducer.reducer
  },
});

export default store;



