import React, { useEffect, useState } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Header } from "../../../commen/Header";
import { Profile } from "../profile/Profile";
import { GetDataWithToken } from "../../../ApiHalper/ApiHelper";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../commen/Loader";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb";

export const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [lodar, setLodar] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if ($.fn.DataTable.isDataTable("#invoiceTable")) {
      $("#invoiceTable").DataTable().destroy();
    }
    setLodar(true);
    GetDataWithToken("customer/invoices").then((res) => {
      if (res?.success) {
        setLodar(false);
        setInvoiceData(res?.data);
      } else if (res.status === 401) {
        navigate("/login");
        Cookies.remove("token");
        toast.error("Session Expire ! Please Login again");
      }
    });

    setTimeout(() => {
      $("#invoiceTable").DataTable({
        ordering: false,
        info: false,
        responsive: true,
        language: {
          sLengthMenu: "_MENU_",
          search: '<i class="fa-solid fa-magnifying-glass serachicon"></i>',
          searchPlaceholder: "Search",
        },
      });
    }, 1000);

    return () => {
      if ($.fn.DataTable.isDataTable("#invoiceTable")) {
        $("#invoiceTable").DataTable().destroy();
      }
    };
  }, []);

  const handleDownload = async (e, id) => {
    try {
      const response = await GetDataWithToken(`customer/invoice/pdf/${id}`);
      const downloadLink = response?.data?.invoicePdf;
      // console.log(downloadLink)

      if (downloadLink) {
        const downloadElement = document.createElement("a");
        downloadElement.href = downloadLink;
        downloadElement.download = "invoice.pdf";
        downloadElement.click();
      } else {
        console.error("Download link not found.");
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <div className="tanent-sec">
        <Header />
        <div className="after-landing-section">
          <Profile activeClass={"invoice"} />
          <div className="links-filter">
            <BreadCrumb data={{ links: 'Invoices' }} />
          </div>
          {lodar ? (
            <Loader />
          ) : (
            <div className="table-responsive data-table text24">
              <table id="invoiceTable" className="table">
                <thead>
                  <tr className="table-header">
                    <th className="title20">S.NO</th>
                    <th className="title20">Date</th>
                    <th className="title20">Name</th>
                    <th className="title20">Event</th>
                    <th className="title20">Invoice</th>
                    <th className="title20">Payment</th>
                    <th className="title20">Status</th>
                    <th className="title20">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData?.invoices &&
                    invoiceData?.invoices?.length > 0 &&
                    invoiceData?.invoices?.map((item, key) => (
                      <tr className="table-data">
                        <td className="">{key + 1}</td>
                        <td className="">{item?.invoice_date}</td>
                        <td className="lightBlack">
                          {item?.customer?.customer_name}
                        </td>
                        <td className="">{item?.event?.name}</td>
                        <td className="">{item?.invoice_number}</td>
                        <td className="">{item?.payment_method}</td>
                        <td className="">{item?.status}</td>
                        <td className="action">
                          <button
                            className="download"
                            type="button"
                            title="Invoice Download"
                            onClick={(e) => handleDownload(e, item?.id)}
                          >
                            <i className="fa-solid fa-download"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Invoice;
