import React, { useEffect,useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import $ from "jquery";
import { useSelector } from "react-redux";

const Slider1 = ({ sliderData }) => {
  // const imageBaseUrl = useSelector(
  //   (state) => state?.imageBaseUrlReducer?.action?.data?.base_url_storage
  // );
  // const baseUrl = window.location.origin;
  // const baseUrl ='https://test.webbytroops.in'


  // Create a ref to keep track of the loaded images

  function counter(event) {
    var items = event.item.count; // Number of items
    var item = event.item.index + 1; // Position of the current item
    if (item > items) {
      item = item - items;
    }
    $(".counter").html(
      `<span class='number'>${item}</span>/<span class='total number'>${items}</span>`
    );
  }
  return (
    <>
      {sliderData?.main_slider_images?.length > 0 &&
        sliderData?.main_slider_type?.value == "first" && (
          <div className="landing-pg" id="Home">
            <OwlCarousel
              className="owl-theme"
              id="landingPgSlider"
              loop={true}
              responsiveClass={true}
              items={1}
              margin={10}
              nav={true}
              autoPlay={true}
              autoplayTimeout={500}
              navText={[
                "<i class='fa-solid fa-arrow-left'></i> <span class='owl-pre' role='presentation' >Prev</span>",
                "<i class='fa-solid fa-arrow-right'></i> <span class='owl-nex' role='presentation'>Next</span>",
              ]}
              autoplayHoverPause={false}
              animateIn={"fadeIn"}
              onChanged={counter}
              responsive={{
                1: {
                  items: 1,
                  dots: true,
                },
                769: {
                  items: 1,
                  dots: false,
                },
              }}
              // counter all items
              onInitialized={counter} //When the plugin has initialized.
              onTranslated={counter} //When the translation of the stage has finished.
            >
              {sliderData?.main_slider_images &&
                sliderData?.main_slider_images?.length > 0 &&
                sliderData?.main_slider_images?.map((item, key) => (
                  <div className="item" key={key}>
                    <div className="container-fluid p-0">
                      <div className="d-flex justify-content-between">
                        <div className="landing-left">
                          <div className="verticaltext">
                            <h1 className="heading62">{item?.main_title}</h1>
                            <p className="text24">{item?.description}</p>
                          </div>
                          <h3 className="event-heading heading62">
                            {item?.title}
                          </h3>
                        </div>
                        <div className="landing-right">
                          <img
                            src={`${item?.image}`}
                            alt="landing slider image"
                            className="img-fluid lr-img"
                            loading="lazy"
                          />
                          <div className="counter"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
        )}
    </>
  );
};

export default Slider1;



