import React from "react";

export const Loader = ({fullPageClass}) => {
  return (
    <>
      <div className={`${fullPageClass?.class} pageloader`}>
        <div className="photoloader mb-0"></div>
        <h1 className="mt-3 text-center">Loading...</h1>
      </div>
    </>
  );
};
