import React, { useEffect, useState } from "react";
import { Header } from "../../../commen/Header";
// import { GuestProfile } from "../guestProfile/GuestProfile";
import { useLocation, Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {
  GetDataWithToken,
  GetDataWithTokenGust,
  GustGetDataWithTokenForLazy,
} from "../../../ApiHalper/ApiHelper";

export const GuestImages = ({ data }) => {
  const [img, setImg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [functions, setFunctions] = useState({
    selectedFunction: undefined,
    functions: [],
  });
  const loaction = useLocation();
  // console.log("jai shree ram",data)

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };


  useEffect(() => {
    GetDataWithTokenGust(
      `event/guest/function/images/${data?.event_id}/${data?.function_id}`
    ).then((res) => {
      setImg(res?.data);
    });

    GetDataWithTokenGust(
      `event/guest/functions/${data?.event_id}`
    ).then((res) => {
      if (res?.success) {
        setFunctions((prevFunctions) => ({
          ...prevFunctions,
          functions: res.data,
        }));
      }
    });
  }, [useLocation, data?.function_id]);

  // useEffect(() => {
  //   // Add the scroll event listener when the component mounts
  //   window.addEventListener("scroll", handleScroll);
  //   // Cleanup function to remove the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [img]);


  // const handleScroll = () => {
  //   const scrollPosition = window?.scrollY;
  //   const totalHeight = document?.body?.scrollHeight;
  //   const windowHeight = window?.innerHeight;

  //   if (scrollPosition + windowHeight >= totalHeight) {
  //     // console.log(selectUnSelectImgRef.current);
  //     // handleSelectAllImages(selectUnSelectImgRef.current);
  //     if (img?.next_page_url !== null && img?.next_page_url) {
  //       GustGetDataWithTokenForLazy(img?.next_page_url).then((res) => {
  //         const newNextPageUrl = res?.data?.next_page_url;
  //         if (res?.data && Array?.isArray(res?.data?.data?.image)) {
  //           setImg((prevImg) => {
  //             // Filter out duplicate images by ID
  //             const filteredImages = res?.data?.data?.image?.filter(
  //               (newImage) => {
  //                 return !prevImg?.data?.image?.some(
  //                   (oldImage) => oldImage?.id === newImage?.id
  //                 );
  //               }
  //             );
  //             return {
  //               ...prevImg,
  //               next_page_url: newNextPageUrl,
  //               data: {
  //                 ...prevImg?.data,
  //                 image: [...prevImg?.data?.image, ...filteredImages],
  //               },
  //               current_page: res?.current_page,
  //             };
  //           });
  //         }
  //       });
  //     }
  //   }
  // };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window?.scrollY;
      const totalHeight = document?.body?.scrollHeight;
      const windowHeight = window?.innerHeight;

      if (!isLoading && scrollPosition + windowHeight >= totalHeight) {
        if (img?.next_page_url !== null && img?.next_page_url) {
          setIsLoading(true);

          GustGetDataWithTokenForLazy(img?.next_page_url)
            .then((res) => {
              const newNextPageUrl = res?.data?.next_page_url;
              if (res?.data && Array?.isArray(res?.data?.data?.image)) {
                setImg((prevImg) => {
                  const filteredImages = res?.data?.data?.image?.filter(
                    (newImage) => {
                      return !prevImg?.data?.image?.some(
                        (oldImage) => oldImage?.id === newImage?.id
                      );
                    }
                  );
                  return {
                    ...prevImg,
                    next_page_url: newNextPageUrl,
                    data: {
                      ...prevImg?.data,
                      image: [...prevImg?.data?.image, ...filteredImages],
                    },
                    current_page: res?.current_page,
                  };
                });
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [img, isLoading]);

  const handleGetAllImages = (name) => {
    setFunctions((prevFunctions) => ({
      ...prevFunctions,
      selectedFunction: "ALL IMAGES",
    }));
    GetDataWithTokenGust(
      `event/guest/all/images/${data?.event_id}`
    ).then((res) => {
      setImg(res?.data);
    });
  };

  const handleGetImagesByFunctions = (function_id, name) => {
    setFunctions((prevFunctions) => ({
      ...prevFunctions,
      selectedFunction: name,
    }));
    GetDataWithTokenGust(
      `event/guest/function/images/${data?.event_id}/${function_id}`
    ).then((res) => {
      setImg(res?.data);
    });
  };

  return (
    <>
      {/* <Header /> */}
      {/* <GuestProfile /> */}
      <div className="events">
        <div className="links-filter">
            <h4 className="title20 mb-0">FILTER</h4>
            <div className="filters">
              <button
                className="btn dropdown-toggle filter-btn"
                type="button"
                id="dropdownMenu2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {functions?.selectedFunction ? functions?.selectedFunction : "Select Function"}
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenu2"
              >
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={handleGetAllImages}
                  >
                    All Images
                  </button>
                </li>
                {Object?.entries(functions?.functions)?.map(
                  ([key, value]) => (
                    <li key={key}>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          handleGetImagesByFunctions(key, value)
                        }
                      >
                        {value}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </div>
        </div>
      </div>
      <div className="event-images row">
        {img?.data?.image &&
          img?.data?.image?.length > 0 &&
          img?.data?.image?.map((item, index) => (
            <div className={"col-4"} key={index}>
              <a
                href="javascript:void(0)"
                className="porto-item"
                onClick={() => {
                  openLightbox(index);
                  // inputCheckbox(item?.id)
                  // setId(index);
                }}
              >
                <LazyLoadImage
                  src={item?.image}
                  alt="portfolio thumbnail"
                  className="img-fluid object-height"
                  effect="blur"
                  loading="lazy" // Add lazy loading
                />
              </a>
            </div>
          ))}
      </div>
      {/* end :: events section  */}
      {isOpen && img && img?.data && img?.data?.image && (
        <div>
          <Lightbox
            mainSrc={img?.data?.image[photoIndex]?.image}
            autoPlay={true}
            slideInterval={1000}
            imageTitle={photoIndex + 1 + "/" + img?.data?.image?.length}
            nextSrc={
              img.data.image[(photoIndex + 1) % img?.data?.image?.length]
                ?.image
            }
            prevSrc={
              img.data.image[
                (photoIndex + img?.data?.image?.length - 1) %
                img?.data?.image?.length
              ]?.image
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => {
              setPhotoIndex(
                (photoIndex + img?.data?.image?.length - 1) %
                img?.data?.image?.length
              );

              // setId(
              //   (photoIndex + img?.data?.image?.length - 1) %
              //     img?.data?.image?.length
              // );
            }}
            onMoveNextRequest={() => {
              setPhotoIndex((photoIndex + 1) % img?.data?.image?.length);
              // setId((photoIndex + 1) % img?.data?.image?.length);
            }}
          //   toolbarButtons={inputCheckbox(id)}
          />
        </div>
      )}
    </>
  );
};
