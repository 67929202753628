import React, { useEffect, useState } from "react";
import { Profile } from "../profile/Profile";
import { Header } from "../../../commen/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GetDataWithToken } from "../../../ApiHalper/ApiHelper";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Loader } from "../../../commen/Loader";

export const Folder = () => {
  const [folderData, setFolderData] = useState([]);
  const [lodar, setLodar] = useState(true);

  const loaction = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setLodar(true);
    GetDataWithToken(
      `customer/event/${loaction?.state?.id || loaction?.state?.event_id
      }/functions`
    ).then((res) => {
      if (res?.success) {
        setFolderData(res?.data);
        setLodar(false);
      } else if (res.status === 401) {
        navigate("/login");
        Cookies.remove("token");
        toast.error("Session Expire ! Please Login again");
      }
    });
  }, []);
  return (
    <>
      <div className="tanent-sec">
        <Header />
        <div className="after-landing-section">
          <Profile activeClass={"events"} />

          <div className="links-filter">
            <BreadCrumb data={{
              state: loaction?.state,
              folderRoute: "/Event/folder",
              functionClass: "title24",
            }} />
          </div>
          {/* begin :: events section  */}
          {lodar ? (
            <Loader />
          ) : (
            <div className="event-folder row">
              {folderData &&
                folderData?.event_name?.length > 0 &&
                folderData?.event_name?.map((item, key) => (
                  <div className="col-lg-3 col-sm-4 mb-3" key={key}>
                    <Link
                      to={"/Event/folder/image"}
                      state={{
                        function_id: item?.id,
                        function_name: item?.name,
                        event_name: loaction?.state?.event_name,
                        event_id: loaction?.state?.event_id,
                      }}
                      className="folder"
                    >
                      {/* <img
                        src="../assets/media/imgGroup.png"
                        alt="Image Tag"
                        className="img-fluid"
                      /> */}
                      <div className="thumbnail">
                        <img
                          src={item?.function_logo ? item?.function_logo : '../assets/media/imgGroup.png'}
                          alt="Image Tag"
                          className="img-fluid"
                        />
                      </div>
                      <h4 className="text24 mb-1">{item?.name}</h4>
                      <p className="text16">{item?.total_image} Images</p>
                    </Link>
                  </div>
                ))}
            </div>
          )}
          {/* end :: events section  */}
        </div>
      </div>
    </>
  );
};
