import React, { useEffect, useState, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Header } from "../../commen/Header";
import Slider1 from "./slider/Slider1";
import { About } from "../about/About";
import { GetData } from "../../ApiHalper/ApiHelper";
import { PortFoliyo } from "../portFoliyo/PortFoliyo";
import { ContectUs } from "../contectUs/ContectUs";
import { Footer } from "../../commen/Footer";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../commen/Loader";
import { Sarvices } from "../Sarvices/Sarvices";
import { Features } from "../features/Features";
import { animateScroll as scroll } from "react-scroll";
import Cookies from "js-cookie";
import { Instagram } from "../instagramFeed/Instagram";

export const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [lodar, setLodar] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const scrollToHash = () => {
      const hash = window.location.hash;
      if (hash === "#about") {
        window.scrollTo(0, document.getElementById("about")?.offsetTop);
      } else if (hash === "#portfolio") {
        window.scrollTo(0, document.getElementById("portfolio")?.offsetTop);
      } else if (hash === "#contect") {
        window.scrollTo(0, document.getElementById("Contact")?.offsetTop);
      } else if (hash === "#srvc") {
        window.scrollTo(0, document.getElementById("srvc")?.offsetTop);
      } else if (hash === "#feature") {
        window.scrollTo(0, document.getElementById("feature")?.offsetTop);
      } else if (hash === "#Home") {
        window.scrollTo(0, document.getElementById("Home")?.offsetTop);
      }
    };
    scrollToHash();
    const unlisten = navigate(() => {
      scrollToHash();
    });
    return () => {
      {
        unlisten && unlisten();
      }
    };
  }, [navigate, window.location.hash]);

  useEffect(() => {
    GetData("customer/home-page").then((res) => {
      setLodar(true);
      if (res?.success) {
        // console.log(res?.data);
        setDashboardData(res?.data);
        Cookies.set("logoImg", res?.data?.general?.general?.company_logo);
        setLodar(false);
      } else {
        // console.log("error dashboard api");
      }
    });
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 200) {
        document.getElementById("scrollToTop")?.classList?.remove("d-none");
      } else {
        document.getElementById("scrollToTop")?.classList?.add("d-none");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {lodar ? (
        <Loader fullPageClass={{class:'fullpage'}}/>
      ) : (
        <div>
          <Header logo={dashboardData?.general?.general} />
          {dashboardData?.home_page?.main_slider?.main_slider_type?.value ==
            "first" && (
              <Slider1
                sliderData={
                  dashboardData?.home_page?.main_slider?.main_slider_type?.value == "first" && dashboardData?.home_page?.main_slider
                }
              />
            )}
          <div className="after-landing-section">
            <About about_us={dashboardData?.home_page?.about_us} />

            <Sarvices
              services={dashboardData?.services}
              header={dashboardData?.home_page?.services}
              footer={dashboardData?.home_page?.footer}
              portfolios={dashboardData?.portfolios}
            />

            <Features data={dashboardData?.home_page?.features} />

            <PortFoliyo
              portfoliyoHeding={dashboardData?.home_page?.portfolios}
              data={dashboardData?.portfolios}
            />

            <Instagram instaPost={dashboardData?.insta_feed} />

            <ContectUs />
            <Footer footer={dashboardData?.home_page?.footer} />

            <a
              href="/#Home"
              className={`topbtn d-none`}
              title="Go To Top"
              id="scrollToTop"
            >
              <i className="fa-regular fa-circle-up"></i>
              
            </a>
          </div>
        </div>
      )}
    </>
  );
};
