import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Profile } from "../profile/Profile";
import { Header } from "../../../commen/Header";
import {
  GetDataWithToken,
  PostDataWithToken,
} from "../../../ApiHalper/ApiHelper";
import { GanrateLinkModal } from "./GanrateLinkModal";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Loader } from "../../../commen/Loader";

export const Event = () => {
  const [eventData, setEventData] = useState([]);
  const [publicPrivate, setPublicPrivate] = useState();
  const [ganrateLinkModal, setGanrateLinkModal] = useState(false);
  const [lodar, setLodar] = useState(true);
  const toggleganrateLinkModal = () => setGanrateLinkModal(!ganrateLinkModal);
  const navigate = useNavigate();

  useEffect(() => {
    GetDataWithToken("customer/dashboard/events").then((res) => {
      setLodar(true);
      if (res?.success) {
        setEventData(res?.data?.event);
        setLodar(false);
      } else if (res.status === 401) {
        navigate("/login");
        Cookies.remove("token");
        toast.error("Session Expire ! Please Login again");
      }
    });
  }, []);

  return (
    <>
      <div className="tanent-sec">
        <Header />
        <div className="after-landing-section">
          <Profile activeClass={"events"} />
          {lodar ? (
            <Loader />
          ) : (
            <div>
              <div className="links-filter">
                <BreadCrumb />
              </div>
              <div className="events row">
                {eventData &&
                  eventData?.length > 0 &&
                  eventData?.map((item, key) => (
                    <div className="col-lg-4 col-sm-6" key={key}>
                      <div className="new-event-card" style={{ backgroundImage: `url(${item?.image_path})`, }}>
                        {/* <div className="new-event-card"> */}
                        <div className="card-header">
                          <img
                            src={item?.image_path}
                            alt="events images"
                            className="event-img"
                          />
                          {/* <img src="./assets/media/Photography.jpg" alt="profile" className="img-fluid event-img"/> */}

                          <button onClick={() => { setPublicPrivate(item?.id); setGanrateLinkModal(true); }} className="shareicon btns" >
                            <i className="fa-brands fa-telegram"></i>
                          </button>
                        </div>
                        <Link to={"/Event/folder"} state={{ id: item?.id, event_name: item?.name, event_id: item?.id, }} className="card-content">
                          <div className="name title20">{item?.name}</div>
                          <div className="mt-2 text20"> {item?.total_functions} Folders</div>
                          <div className="date py-2 text20"> {item?.start_date} To {item?.end_date}</div>
                          <div className="text24 project-dtls">
                            <span>Value</span>
                            <span>{item?.estimated_price}</span>
                          </div>
                          <div className="text24 project-dtls">
                            <span>Total Paid</span>
                            <span>{item?.paid_amount}</span>
                          </div>
                          <div className="text24 project-dtls">
                            <span>Images</span>
                            <span>{item?.images}</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                <h1 className="text-center title42">{eventData?.msg && <div> {eventData?.msg} </div>}</h1>
                <GanrateLinkModal
                  eventId={publicPrivate}
                  ganrateLinkModal={{
                    modal: ganrateLinkModal,
                    setModal: setGanrateLinkModal,
                    toggle: toggleganrateLinkModal,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
