import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { PostData } from "../ApiHalper/ApiHelper";
import { toast } from "react-toastify";
import { BtnLoader } from "../commen/BtnLoader";
import Cookies from "js-cookie";

export const NewPassword = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const location = useLocation()
  const email = location.search.replace('?email=', ''); // Extract email from query parameter
  const { token } = useParams()
  const navigate = useNavigate()
  const logoimg = Cookies.get('logoImg')

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  const handleChangePassword = (data) => {
    setBtnLoader(true)
    let allData = { ...data, email: email, token: token }
    PostData("customer/new/password/reset", allData).then((res) => {
      // console.log(res)
      if (res?.success) {
        setBtnLoader(false)
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/")
      } else {
        setBtnLoader(false)
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
  }
  return (
    <>
      <section className="login">
        <div className="login-right">
          {/* Brand Logo */}
          <Link to='/'>
            <img
              src={`${logoimg}`}
              alt="brand-logo-image"
              className="img-fluid brandlogo"
            />
          </Link>
          <div className="login-sec">
            {/* Login Form Section */}
            <form className="tab-content">
              <div className="detail">
                <h1>Welcome back</h1>
                <p>
                  Please Enter your <strong>Code</strong> which you get from
                  studio or login credentials
                </p>
              </div>
              <div className={`position-relative mb-xxl-5 mb-4 ${errors.password ? "geterror" : ""
                }`}>
                <label htmlFor="Code" className="form-label">
                  Enter New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="Code"
                  placeholder="New Password"
                  {...register("password", {
                    required: " ",
                    minLength: {
                      value: 8,
                      message: " ",
                    },
                  })}
                />
                {errors.password && errors.password.message && (
                  <div className="f-error text-danger">
                    <i className="fa-solid fa-xmark"></i>
                    {errors.password && errors.password.message}
                  </div>
                )}
                <span className="inputicon">
                  <i className="fa-solid fa-lock" />
                </span>
              </div>
              <div className={`position-relative mb-xxl-5 mb-4 ${errors.confirmPassword ? "geterror" : ""
                }`}>
                <label htmlFor="Code" className="form-label">
                  Enter Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="Code"
                  placeholder="Confirm Password"
                  {...register("password_confirmation", {
                    required: " ",
                    minLength: {
                      value: 8,
                      message: " ",
                    },
                    validate: (value) =>
                      value === password || 'Passwords do not match',
                  })}
                />
                {errors.confirmPassword && errors.confirmPassword.message && (
                  <div className="f-error text-danger">
                    <i className="fa-solid fa-xmark"></i>
                    {errors.confirmPassword && errors.confirmPassword.message}
                  </div>
                )}
                <span className="inputicon">
                  <i className="fa-solid fa-lock" />
                </span>
              </div>
              <p className="forget-pass">
                Go to login <Link to={'/login'}>Click Here</Link>
              </p>
              <a href="#" className="btns btns--calypso login-btn" onClick={handleSubmit(handleChangePassword)}>
                <span>{btnLoader ? <BtnLoader /> : "Submit"}</span>
              </a>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
