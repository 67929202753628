import React from 'react'
import { Link } from 'react-scroll'

export const Instagram = ({ instaPost }) => {
    return (
        instaPost?.length > 0 && 
        <>
            {<div className="instafeed">
                <div className="row">
                    {instaPost?.length > 0 && instaPost && instaPost?.map((item, key) => (<div className="col-xxl-2 col-md-3 col-sm-4 col-6 my-2">
                        {/* {key < 12 && <div className="posts"> */}
                        <a target='blank' href={item.permalink} className="posts">
                            {/* <a target='blank' href={item.permalink}> */}
                            <img src={item.url} alt="insta post" className='img-fluid' />
                            <p className="caption">{item.caption}</p>
                            {/* </a> */}
                        </a>
                    </div>))}
                </div>
                <div className="insta-follow">
                    <i class="fa-brands fa-instagram"></i>
                    <h1 className='heading62'>Instagram</h1>
                    <a target='blank' href='#' className='btns btns--calypso'>
                        <span>Follow Us</span>
                    </a>
                </div>
            </div>}
        </>
    )
}
