import { createSlice } from "@reduxjs/toolkit";


const BaseUrlSlice = createSlice({
  name: "BaseUrlSlice",
  initialState: [],
  reducers: {
    BaseUrlSlice: (state, action) => {
      return { ...state, action: action.payload };
    },
  },
});


const userInfoSlice = createSlice({
    name: "userInfoSlice",
    initialState: [],
    reducers: {
        userInfoSlice: (state, action) => {
        return { ...state, action: action.payload };
      },
    },
  });

  const isLoggedInSlice = createSlice({
    name: "isLoggedInSlice",
    initialState: false,
    reducers: {
      isLoggedInSlice: (state, action) => {
        return { ...state, action: action.payload };
      },
    },
  });

  const imageBaseUrlSlice = createSlice({
    name: "imageBaseUrlSlice",
    initialState: "",
    reducers: {
      imageBaseUrlSlice: (state, action) => {
        return { ...state, action: action.payload };
      },
    },
  });

  const logoSlice = createSlice({
    name: "logoSlice",
    initialState: "",
    reducers: {
      logoSlice: (state, action) => {
        return { ...state, action: action.payload };
      },
    },
  });

  const FaceDataSlice = createSlice({
    name: "FaceDataSlice",
    initialState: [],
    reducers: {
      // FaceDataSlice: (state, action) => {
      //   return { ...state, action: action.payload };
      // },
      updateFaceData: (state, action) => {
        const uniquePaths = action.payload.filter((path, index, self) => self.indexOf(path) === index);
      state.push(...uniquePaths);
      },
      emptyFaceData: (state) => {
        return []; // Reset state to empty array
      },
    },
  });


  export const FaceDataReducer=FaceDataSlice
  export const actionfaceData=FaceDataSlice.actions
  export const logoReducer=logoSlice
  export const actionLogo=logoSlice.actions
  export const imageBaseUrlReducer=imageBaseUrlSlice
  export const actionImageBaseUrl=imageBaseUrlSlice.actions
  export const isLoggedInReducer=isLoggedInSlice
  export const actionUserLoggedIn=isLoggedInSlice.actions
  export const baseUrlReducer=BaseUrlSlice
  export const actionBaseUrl=BaseUrlSlice.actions
  export const userInfoReducer=userInfoSlice
  export const actionUserInfo=userInfoSlice.actions