import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
export const PortFoliyoModal = ({ modalData, setPortfolyoModal }) => {

  return (
    <>
      <Modal
        isOpen={setPortfolyoModal?.portfolyoModal}
        toggle={setPortfolyoModal?.togglePortfolyoModal}
        className="portfolio-popup" // Add a custom class name to the modal
        id="portfolioDetail"
      >
        {/* <ModalHeader toggle={setPortfolyoModal?.togglePortfolyoModal}>
          Modal Title
        </ModalHeader> */}
        <ModalBody className="p-0">
          <button
            type="button"
            className="btn-close popup-close"
            // data-bs-dismiss="modal"
            onClick={setPortfolyoModal?.togglePortfolyoModal}
          />
          <div className="popup-about">
            <div className="row">
              <div className="col-6 position-relative">
                <img
                  src={modalData?.main_image}
                  alt="popup thumbnail"
                  className="img-fluid translate-img"
                />
              </div>
              <div className="col-6">
                <div className="popup-content">
                  <h3 className="heading52">About</h3>
                  <p className="text24">{modalData?.about_description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="popup-review">
            <div className="row">
              <div className="col-6">
                <div className="popup-content">
                  <h3 className="heading52">Customer</h3>
                  <p className="text24">
                    {modalData?.approved_review?.description}
                  </p>
                  <div className="reviewer">
                    <img
                      src={
                        modalData?.approved_review?.customer_image ||modalData?.approved_review?.customer?.image_path
                          
                      }
                      alt="Reviewer image"
                      className="img-fluid"
                    />
                    <div className="reviewer-details ms-sm-3 ms-2">
                      <h5 className="name text24 m-0">
                        {" "}
                        {modalData?.approved_review?.customer_name ||modalData?.approved_review?.customer?.customer_name}
                      </h5>
                      <p className="date">
                        {" "}
                        {modalData?.approved_review?.date}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 position-relative popup-review-left">
                <img
                  src={modalData?.mid_sub_image}
                  alt="popup thumbnail"
                  className="img-fluid translate-img2 position-absolute"
                />
                <img
                  src={modalData?.mid_main_image}
                  alt="popup thumbnail"
                  className="img-fluid translate-img2"
                />
              </div>
            </div>
          </div>
          <div className="portfolios">
            <h2 className="heading52 text-center">portfolio</h2>
            <div className="row">
              {modalData?.portfolio_media?.length > 0 &&
                modalData?.portfolio_media?.map((item, key) => (
                  <div className="col-md-4 col-sm-6" key={key}>
                    <div
                      // target="blank"
                      className="porto-item"
                    // data-bs-toggle="modal"
                    // data-bs-target="#portfolioDetail"
                    >
                      <img
                        src={item?.image}
                        alt="portfolio thumbnail"
                        className="img-fluid object-height"
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="modal-footer">
            <p className="end">
              Thank You For Take Look on Our Project
            </p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
