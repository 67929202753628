import React from "react";
import { Link } from "react-router-dom";

export const BreadCrumb = ({ data }) => {
  return (
    <>
      <div className="bredcrmb">
          <ul className="links">
            <li>
              <Link to={"/profile/Event"} className='title20'>
                {data?.links || 'Events'}
              </Link>
            </li>
            {data?.state?.event_name && (
              <li>
                <Link to={data?.folderRoute} state={{ id: data?.state?.id, event_name: data?.state?.event_name, event_id: data?.state?.event_id, }} className='linknames'>
                  {data?.state?.event_name}
                </Link>
              </li>
            )}
            {data?.selectedFunction && <li>
              <a className="linknames">
                {data?.selectedFunction}
              </a>
            </li>}
          </ul>
      </div>
    </>
  );
};
