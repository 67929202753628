import React, { useEffect, useState } from "react";
import {
  DeleteDataWithToken,
  GetDataWithToken,
} from "../../../ApiHalper/ApiHelper";
import { Profile } from "../profile/Profile";
import { Header } from "../../../commen/Header";
import { GanrateLinkModal } from "../event/GanrateLinkModal";
import { toast } from "react-toastify";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Loader } from "../../../commen/Loader";
import Swal from "sweetalert2";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb";

export const EventToken = () => {
  const [tokenData, setTokenData] = useState([]);
  const [editData, setEditData] = useState({});
  const [callApi, setCallApi] = useState(true);
  const [ganrateLinkModal, setGanrateLinkModal] = useState(false);
  const [lodar, setLodar] = useState(true);

  const toggleganrateLinkModal = () => setGanrateLinkModal(!ganrateLinkModal);
  const navigate = useNavigate();

  useEffect(() => {
    if ($.fn.DataTable.isDataTable("#eventTable")) {
      $("#eventTable").DataTable().destroy();
    }
    GetDataWithToken("customer/event-tokens").then((res) => {
      setLodar(true);
      if (res?.success) {
        setTokenData(res?.data);
        setCallApi(false);
        setLodar(false);
      } else if (res.status === 401) {
        navigate("/login");
        Cookies.remove("token");
        toast.error("Session Expire ! Please Login again");
      }
      // Use 0 ms delay to ensure it runs after the rendering cycle
    });

    setTimeout(() => {
      $("#eventTable").DataTable({
        ordering: false,
        info: false,
        responsive: {
          breakpoints: [
            { name: 'meddesktop', width: 1480 },
          ]
        },
        language: {
          sLengthMenu: "_MENU_",
          search: '<i class="fa-solid fa-magnifying-glass serachicon"></i>',
          searchPlaceholder: "Search",
        },
      });
    }, 0);

    // Clean up the DataTable instance when the component is unmounted
    return () => {
      if ($.fn.DataTable.isDataTable("#eventTable")) {
        $("#eventTable").DataTable().destroy();
      }
    };
  }, [callApi]);

  const copyReferral = (token) => {
    navigator.clipboard.writeText(`${token}`);
    toast?.success("text Copied");
  };

  const handleEdit = (data) => {
    setEditData(data);
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to Delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteDataWithToken(`customer/event-tokens/${id}`).then((res) => {
          setLodar(true);
          if (res?.success) {
            setCallApi(true);
            setLodar(false);
          }
        });
      }
    });
  };

  return (
    <>

      <div className="tanent-sec">
        <Header />
        <div className="after-landing-section">
          <Profile activeClass={"EventToken"} />

          <div className="links-filter">
            <BreadCrumb data={{ links: 'Event Token' }} />
          </div>
          {lodar ? (
            <Loader />
          ) : (
            <div className="table-responsive data-table text24">
              <table id="eventTable" className="table">
                <thead>
                  <tr className="table-header">
                    <th className="title20">S.NO</th>
                    <th className="title20">Tokens</th>
                    <th className="title20">Privacy</th>
                    <th className="title20">Event Name</th>
                    <th className="title20">Event Functions</th>
                    <th className="title20">Guest Name</th>
                    <th className="title20">Email</th>
                    <th className="title20">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tokenData &&
                    tokenData?.length > 0 &&
                    tokenData?.map((item, key) => (
                      <tr className="table-data">
                        <td className="">{key + 1}</td>
                        <td
                          className="copy_token"
                          title="Copy Token"
                          onClick={() => copyReferral(item?.token)}
                        >
                          {item?.token}
                        </td>
                        <td>
                          {item?.privacy === 1 ? "Private" : "Public"}
                        </td>
                        <td className="">{item?.event?.name}</td>
                        <td className="">
                          {item?.event_functions
                            .map((func) => func.function_name)
                            .join(", ")}
                        </td>
                        <td className="">{item?.name}</td>
                        <td className="">{item?.email}</td>
                        <td className="action">
                          <button onClick={() => { handleEdit(item); toggleganrateLinkModal(); }} className="btns icon title20" title="Edit">
                            <i class="fa-solid fa-pen-to-square"></i>
                          </button>
                          <button className="btns icon title20" onClick={() => handleDelete(item?.id)} title="Delete">
                            <i class="fa-solid fa-trash-can"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}

          <GanrateLinkModal
            eventId={editData?.event_id}
            editData={editData}
            ganrateLinkModal={{
              modal: ganrateLinkModal,
              setModal: setGanrateLinkModal,
              toggle: toggleganrateLinkModal,
              callApi: setCallApi,
            }}
          />
        </div>
      </div>
    </>
  );
};
