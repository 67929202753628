import React, { useEffect, useState } from "react";
import { Header } from "../../commen/Header";
import { Footer } from "../../commen/Footer";
import { GetData, GetDataWithToken } from "../../ApiHalper/ApiHelper";
import { Link, useLocation } from "react-router-dom";
import { Loader } from "../../commen/Loader";

export const AllSarviceList = () => {
  const [allSarvice, setAllSarvice] = useState([]);
  const [lodar, setLodar] = useState(true);
  const loction = useLocation()
  useEffect(() => {
    GetData("customer/home-page/services").then((res) => {
      setLodar(true);
      if (res?.success) {
        setAllSarvice(res?.data?.services);
        setLodar(false);
      }
    });
  }, []);

  return (
    <>
      {lodar ? (
        <Loader  fullPageClass={{class:'fullpage'}}/>
      ) : (
        <div>
          <Header />
          <div className="after-landing-section">

            <div className="all-srvces">

              <h1 className="heading52"><Link to={-1} className="backbtn" title="Back"><i class="fa-solid fa-left-long"></i></Link>All Services</h1>
              <div class="row">
                {allSarvice &&
                  allSarvice?.length > 0 &&
                  allSarvice?.map((item, key) => (
                    <div class="col-lg-3 col-md-4 col-sm-6 p-3 m-0">
                      <Link
                        className="srvc-menu-item"
                        to={"/service/Detail"}
                        state={{ data: item ,footer:loction?.state?.footer}}
                      >
                        <div className={`icon img${(key % 12) + 1}`}></div>
                        <h3 className="title24">{item?.heading}</h3>
                      </Link>
                    </div>
                  ))}
              </div>
              <Footer footer={loction?.state?.footer} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
