import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"; // Import ScrollTrigger separately
import ScrollToPlugin from "gsap/ScrollToPlugin";


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); // Register both plugins

export const Features = ({ data }) => {

  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  //   const featureElements = [
  //     {id: "#feautreheading", x: 0, y: 50, start: "top bottom+=200px"},
  //     {id: "#featureTopLeft", x: 150, y: 100, start: "top bottom+=200px"},
  //     {id: "#featureTopRight", x: -100, y: 150, start: "top bottom+=200px"},
  //     {id: "#featureCenter", x: 0, y: 100, start: "top bottom"},
  //     {id: "#featureBottomLeft", x: -100, y: 100, start: "top center"},
  //     {id: "#featureBottomRight", x: 160, y: 140, start: "top center"},
  //   ];

  //   featureElements.forEach(({ id, x, y, scale, start }) => {
  //     gsap.to(id, {
  //       x,
  //       y,
  //       scale,
  //       scrollTrigger: {
  //         trigger: ".media-wrapper",
  //         // start,
  //         end: "bottom center",
  //         scrub: true,
  //         toggleActions: "restart none none none none",

  //       },
  //     });
  //   });
  // }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    let featureElements = []
    const applyAnimations = () => {
      if (window.innerWidth >= 768) {
        featureElements = [
          // { id: "#feautreheading", x: 0, y: 50, start: "top bottom+=200px" },
          // { id: "#featureTopLeft", x: 100, y: 100, start: "top bottom+=200px" },
          // { id: "#featureTopRight", x: -100, y: 100, start: "top bottom+=200px" },
          // { id: "#featureCenter", x: 0, y: 200, scale: 1.08, start: "top bottom" },
          // { id: "#featureBottomLeft", x: -50, y: 90, start: "center center" },
          // { id: "#featureBottomRight", x: 80, y: 80, start: "center center" },
          { id: "#feautreheading", x: '0', y: "4vw", start: "top-=100px bottom+=200px" },
          { id: "#featureTopLeft", x: '2vw', y: '7vw', start: "top-=100px bottom+=200px" },
          { id: "#featureTopRight", x: '-7vw', y: '4vw', start: "top-=100px bottom+=200px" },
          { id: "#featureCenter", x: '0', y: '4vw', scale: 1.08, start: "top-=100px bottom" },
          { id: "#featureBottomLeft", x: '-6vw', y: '9vw', start: "center-=300px bottom" },
          { id: "#featureBottomRight", x: '6vw', y: '9vw', start: "center-=300px bottom" },
        ];
        // before 768px
      }
      else {
        featureElements = [
          { id: "#feautreheading", x: '0', y: '6vw', start: "top bottom+=100px" },
          { id: "#featureTopLeft", x: '8vw', y: '15vw', start: "top bottom+=100px" },
          { id: "#featureTopRight", x: '-15vw', y: '6vw', start: "top bottom+=100px" },
          { id: "#featureCenter", x: '0', y: '10vw', scale: 1, start: "top bottom" },
          { id: "#featureBottomLeft", x: '-4vw', y: '16vw', start: "top center" },
          { id: "#featureBottomRight", x: '4vw', y: '8vw', start: "top center" },
        ];
        // after 768px
      }


      featureElements.forEach(({ id, x, y, scale, start }) => {
        gsap.to(id, {
          x,
          y,
          scale,
          scrollTrigger: {
            trigger: ".media-wrapper",
            start,
            end: "bottom center",
            scrub: true,
            toggleActions: "restart none none none none",
          },
        });
      });
    };

    // Check for the screen width and apply animations accordingly
    const handleResize = () => {
      applyAnimations();
    };

    // Initial check on component mount
    handleResize();

    // Attach event listener for future changes in screen width
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* begin :: features section  */}
      <section className="feature-sec" id="feature">
        <h1 className="heading62" id="feautreheading">{data?.feature_title}</h1>

        <ul className="media-wrapper">
          <li className="feature top-left" id="featureTopLeft">
            <img src={data?.feature_top_right_image} alt="feature images" className="img-fluid img-fea" />
          </li>

          <li className="feature top-right" id="featureTopRight">
            <img src={data?.feature_top_left_image} alt="feature images" className="img-fluid img-fea" />
          </li>

          <li className="feature center" id="featureCenter">
            <img src={data?.feature_main_image} alt="feature images" className="img-fluid img-fea" />
          </li>

          <li className="feature bottom-left" id="featureBottomLeft">
            <img src={data?.feature_bottom_right_image} alt="feature images" className="img-fluid img-fea" />
          </li>

          <li className="feature bottom-right" id="featureBottomRight">
            <img src={data?.feature_bottom_left_image} alt="feature images" className="img-fluid img-fea" />
          </li>

        </ul>
        {/* <div className="feature top-left" id="featureTopLeft">
         <h3 className="text42">{data?.feature_top_right_title}</h3>
          <img
            src={data?.feature_top_right_image} alt="feature images" className="img-fluid img-fea"
          />
        </div> 
         <div className="feature top-right" id="featureTopRight">
           <h3 className="text42">{data?.feature_top_left_title} </h3>
          <img
            src={data?.feature_top_left_image}
            alt="feature images"
            className="img-fluid img-fea"
          />
        </div> 
         <div className="feature center" id="featureCenter">
          <img src={data?.feature_main_image} alt="feature images" className="img-fluid img-fea" />
        </div> 
        <div className="feature bottom-left" id="featureBottomLeft">
           <h3 className="text42">{data?.feature_bottom_right_title}</h3>
          <img src={data?.feature_bottom_right_image} alt="feature images" className="img-fluid img-fea" />
        </div>
        <div className="feature bottom-right" id="featureBottomRight">
          <h3 className="text42">{data?.feature_bottom_left_title}</h3> 
          <img src={data?.feature_bottom_left_image} alt="feature images" className="img-fluid img-fea" />
        </div> */}
      </section>
      {/* end :: features section  */}
    </>
  );
};


// import React, { useEffect } from 'react';
// import { motion, useAnimation } from 'framer-motion';
// const Feature = ({ cls, id, x, y, scale, imageSrc }) => {
//   const controls = useAnimation();

//   useEffect(() => {
//     const animateFeature = () => {
//       controls.start({
//         x,
//         y,
//         scale,
//       });
//     };

//     const handleScroll = () => {
//       const featureElement = document.getElementById(`feature${id}`);
//       if (featureElement) {
//         const elementTop = featureElement.getBoundingClientRect().top;
//         const windowHeight = window.innerHeight;

//         if (elementTop < windowHeight) {
//           animateFeature();
//         }
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, [controls, x, y, scale, id]);

//   return (
//     <motion.div
//       className={`feature ${cls}`}
//       id={`feature${id}`}
//       initial={{ opacity: 1, scale: 0.8 }}
//       animate={controls}
//     >
//       <motion.img src={imageSrc} alt="feature images" className="img-fluid img-fea" />
//     </motion.div>
//   );
// };

// export const Features = ({ data }) => {
//   return (
//     <>
//       {/* begin :: features section  */}
//       <section className="feature-sec">
//         <h1 className="heading62 text-center my-5">{data?.feature_title}</h1>
//         <ul className='media-wra'>
//           <li></li>
//         </ul>
//         <Feature cls="top-left" id="TopLeft" x={100} y={200} scale={1.1} imageSrc={data?.feature_top_left_image} />
//         <Feature cls="top-right" id="TopRight" x={-100} y={200} scale={1.1} imageSrc={data?.feature_top_right_image} />
//         <Feature cls="center" id="Center" x={0} y={0} scale={1} imageSrc={data?.feature_main_image} />
//         <Feature cls="bottom-left" id="BottomLeft" x={100} y={100} scale={1.1} imageSrc={data?.feature_bottom_left_image} />
//         <Feature cls="bottom-right" id="BottomRight" x={-100} y={100} scale={1.1} imageSrc={data?.feature_bottom_right_image} />
//       </section>
//       {/* end :: features section  */}
//     </>
//   );
// };