import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { PostData } from "../ApiHalper/ApiHelper";
import { toast } from "react-toastify";
import { BtnLoader } from "../commen/BtnLoader";
import Cookies from "js-cookie";

export const ForgetPassword = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const navigate = useNavigate();
  const logoimg = Cookies.get('logoImg')
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const [forgetType, setForgetType] = useState("");
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);

  const handleInputChange = (event, nextInput, prevInput) => {
    if (event.key === "Backspace") {
      prevInput?.current?.focus();
      return;
    }
    if (event.target.value.length >= 1) {
      nextInput?.current?.focus();
    }
  };

  const handleChangePassword = (data) => {
    setBtnLoader(true)
    PostData("customer/password/reset", data).then((res) => {
      if (res?.success) {
        setBtnLoader(false)
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        setBtnLoader(false)
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <section className="login">
        <div className="login-right">
          {/* Brand Logo */}
          <Link to='/'>
            <img
              src={`${logoimg}`}
              alt="brand-logo-image"
              className="img-fluid brandlogo"
            />
          </Link>
          <div className="login-sec">
            {/* Login Form Section */}
            <form className="tab-content">
              <div className="detail">
                <h1>Welcome back</h1>
                <p>
                  Please Enter your <strong>Code</strong> which you get from
                  studio or login credentials
                </p>
              </div>
              <div className="position-relative mb-xxl-5 mb-4">
                <label htmlFor="Code" className="form-label">
                  Enter Your Mail-id
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Code"
                  placeholder="Enter Your Email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Incorrect Email format",
                    },
                  })}
                />
                {errors.email && errors.email.message && (
                  <div className="f-error text-danger">
                    <i className="fa-regular fa-circle-xmark me-1" />
                    {errors.email && errors.email.message}
                  </div>
                )}
                <span className="inputicon">
                  <i className="fa-solid fa-envelope" />
                </span>
              </div>
              {/* <input
                type="radio"
                name="option"
                id=""
                onChange={() => setForgetType("otp")}
              />
              OTP
              <input
                type="radio"
                name="option"
                id=""
                onChange={() => setForgetType("link")}
              /> */}
              {/* {forgetType == "otp" && (
                <div className="mb-xxl-5 mb-4">
                  <label htmlFor="Code" className="form-label">
                    Enter OTP
                  </label>
                  <div className="otp-input">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={5}
                      ref={input1Ref}
                      maxLength={1}
                      onKeyUp={(event) => handleInputChange(event, input2Ref)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder={2}
                      ref={input2Ref}
                      maxLength={1}
                      onKeyUp={(event) =>
                        handleInputChange(event, input3Ref, input1Ref)
                      }
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder={6}
                      ref={input3Ref}
                      maxLength={1}
                      onKeyUp={(event) =>
                        handleInputChange(event, input4Ref, input2Ref)
                      }
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="*"
                      ref={input4Ref}
                      maxLength={1}
                      onKeyUp={(event) =>
                        handleInputChange(event, input5Ref, input3Ref)
                      }
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="*"
                      ref={input5Ref}
                      maxLength={1}
                      onKeyUp={(event) =>
                        handleInputChange(event, input6Ref, input4Ref)
                      }
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="*"
                      ref={input6Ref}
                      maxLength={1}
                      onKeyUp={(event) =>
                        handleInputChange(event, input6Ref, input5Ref)
                      }
                    />
                  </div>
                </div>
              )} */}
              {forgetType == "link" && <p> Link send in your email </p>}
              <p className="forget-pass">
                Go Back to login? <Link to={"/login"}>Click Here</Link>{" "}
              </p>
              <button
                className="btns btns--calypso login-btn"
                onClick={handleSubmit(handleChangePassword)}
              >
                <span>{btnLoader ? <BtnLoader /> : "Send Link"}</span>
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
