import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  GetDataWithToken,
  PostDataWithToken,
  PutDataWithToken,
} from "../../../ApiHalper/ApiHelper";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const GanrateLinkModal = ({ eventId, editData, ganrateLinkModal }) => {
  const [functionData, setFunctionData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [publicPrivate, setPublicPrivate] = useState({
    public_private: 2,
    event_ID: "",
  });
  const navigate = useNavigate()


  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    setPublicPrivate((prevState) => ({ ...prevState, event_ID: eventId }));
    if (eventId) {
      GetDataWithToken(`customer/event/${eventId}/functions/list`).then(
        (res) => {
          if (res?.success) {
            setFunctionData(
              Object?.keys(res?.data)?.map((key) => ({
                label: res?.data[key],
                value: key,
              }))
            );
          } else if (res.status === 401) {
            navigate("/login");
            Cookies.remove("token");
            toast.error("Session Expire ! Please Login again");
          }
        }
      );
    }

    if (editData) {
      const selectedFunctions = editData?.event_function_ids?.map((id) => ({
        label: getFunctionNameById(id),
        value: id,
      }));
      setSelectedOptions(selectedFunctions);

      setPublicPrivate((prevState) => ({
        ...prevState,
        public_private: editData?.privacy,
      }));

      reset({
        name: editData?.name,
        email: editData?.email,
        mob_no: editData?.mob_no,
      });
    }
  }, [eventId, editData]);


  const getFunctionNameById = (id) => {
    const functionObject = functionData?.find((func) => func?.value === id);
    return functionObject ? functionObject?.label : "Select Functions";
  };


  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  };

  const handleGanrateLink = (data) => {
    const valuesArray = selectedOptions.map((item) => parseInt(item.value, 10));
    const allData = {
      event_id: publicPrivate?.event_ID,
      privacy: publicPrivate?.public_private,
      event_function_ids: valuesArray,
      mob_no: data?.mob_no,
      email: data?.email,
      name: data?.name,
    };

    if (editData) {
      PutDataWithToken(`customer/event-tokens/${editData?.id}`, allData).then(
        (res) => {
          if (res?.success) {
            ganrateLinkModal?.callApi(true);
            reset({
              name: editData?.name,
              email: editData?.email,
              mob_no: editData?.mob_no,
            });
            ganrateLinkModal?.toggle();
            toast?.success(res?.message);
          } else if (res.status === 401) {
            navigate("/login");
            Cookies.remove("token");
            toast.error("Session Expire ! Please Login again");
          }
        }
      );
      return false;
    }

    if (valuesArray?.length > 0) {
      PostDataWithToken("customer/event-tokens", allData).then((res) => {
        if (res?.success) {
          reset({
            name: editData?.name,
            email: editData?.email,
            mob_no: editData?.mob_no,
          });
          ganrateLinkModal?.toggle();
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          const errors = res?.data?.errors;
          if (errors) {
            if (res.status === 401) {
              navigate("/login");
              Cookies.remove("token");
              toast.error("Session Expire ! Please Login again");
            }
            Object?.keys(errors).forEach((field) => {
              errors[field]?.forEach((errorMessage) => {
                toast.error(`${field}: ${errorMessage}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
            });
          }
        }
      });
    } else {
      toast.error("Please Select Minimum 1 Function ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={ganrateLinkModal?.modal}
        toggle={ganrateLinkModal?.toggle}
        className="modal-dialog-centered eventsharemodal"
      >
        <ModalHeader toggle={ganrateLinkModal?.toggle} id="imgShareModalLabel">
          
            share your images
            <img
              className="img-fluid"
              src="../assets/media/black.png"
              alt="img share icon"
            />
        </ModalHeader>
        <ModalBody>
          <div className="login-option">
            <span>Share With</span>
            <span className="title42 mx-xxl-3 mx-2">:</span>
            <ul className="nav">
              <li className="nav-item">
                <button
                  className={`btn ${editData?.privacy == 2 ? "active" : ""} ${!editData && "active"
                    }`}
                  id="public-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#public"
                  type="button"
                  role="tab"
                  aria-controls="public"
                  aria-selected="true"
                  onClick={() =>
                    setPublicPrivate((prevState) => ({
                      ...prevState,
                      public_private: 2,
                    }))
                  }
                >
                  Public
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`btn ${editData?.privacy == 1 ? "active" : ""}`}
                  id="private-tab"
                  // id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#private"
                  type="button"
                  role="tab"
                  aria-controls="private"
                  aria-selected="false"
                  onClick={() =>
                    setPublicPrivate((prevState) => ({
                      ...prevState,
                      public_private: 1,
                    }))
                  }
                >
                  Private
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div
              className={`tab-pane ${editData?.privacy == 2 && "active"} ${!editData && "active"
                } public-tab`}
              id="public"
              role="tabpanel"
              aria-labelledby="public-tab"
            >
              <MultiSelect
                options={functionData}
                value={selectedOptions}
                onChange={handleSelectChange}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select Event Functions",
                }}
              />

              <button
                type="button"
                className="btns btns--calypso sharebtn"
                onClick={handleGanrateLink}
              >
                <span> Share{" "}  <img className="img-share" src="../assets/media/white.png" alt="img share icon" /> </span>
              </button>
            </div>
            <div
              className={`tab-pane private-tab ${editData?.privacy == 1 && "active"
                }  common-form`}
              id="private"
              role="tabpanel"
              aria-labelledby="private-tab"
            >
              {" "}
              <MultiSelect
                options={functionData}
                value={selectedOptions}
                onChange={handleSelectChange}
                labelledBy="Select "
                overrideStrings={{
                  selectSomeItems: "Select Event Functions",
                }}
              />
              <form className="w-100">
                <div
                  className={`inputbox ${errors.name ? "geterror" : ""}`}
                >
                  <label htmlFor="name" className="form-label icon">
                    <i className="fa-solid fa-user" />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter Your Name"
                    {...register("name", {
                      required: " ",
                    })}
                  />
                  {errors.name && errors.name.message && (
                    <div className="f-error text-danger">
                      <i className="fa-solid fa-xmark"></i>
                      {errors.name && errors.name.message}
                    </div>
                  )}
                </div>
                <div
                  className={`inputbox ${errors.email ? "geterror" : ""
                    }`}
                >
                  <label htmlFor="email" className="form-label icon">
                    <i className="fa-solid fa-envelope" />
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter Your E-mail"
                    {...register("email", {
                      required: " ",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: " ",
                      },
                    })}
                  />
                  {errors.email && errors.email.message && (
                    <div className="f-error text-danger">
                      <i className="fa-solid fa-xmark"></i>
                      {errors.email && errors.email.message}
                    </div>
                  )}
                </div>
                <div
                  className={`inputbox ${errors.mob_no ? "geterror" : ""
                    }`}
                >
                  <label htmlFor="mobileNo" className="form-label icon">
                    <i className="fa-solid fa-phone" />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mobileNo"
                    placeholder="+91 Mobile Number"
                    {...register("mob_no", {
                      required: " ",
                      pattern: {
                        value: /^[0-9]{10}$/, // Assuming 10-digit phone number
                        message: " ",
                      },
                    })}
                  />
                  {errors.mob_no && errors.mob_no.message && (
                    <div className="f-error text-danger">
                      <i className="fa-solid fa-xmark"></i>
                      {errors.mob_no && errors.mob_no.message}
                    </div>
                  )}
                </div>
              </form>
              <button
                type="button"
                className="btns btns--calypso sharebtn"
                onClick={handleSubmit(handleGanrateLink)}
              >
                <span> Share{" "} <img className="img-share" src="../assets/media/white.png" alt="img share icon" /></span> 
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};










